body {
    margin: 0;
    font-family: "Arial";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

* {
    box-sizing: border-box;
}
