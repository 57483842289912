@use "./scss-variables/variables" as token;

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: token.$global-color-surface-00-default;

    .site-header {
        border-bottom: 1px solid token.$global-color-surface-02-default;

        .site-nav-bar {
            display: flex;
            justify-content: space-between;
            padding: 20px 100px;

            .left-nav-section {
                display: flex;
                align-items: center;

                .home-link-logo {
                    align-items: center;
                    background-color: #151515;
                    border-radius: 5px;
                    display: flex;
                    height: 42px;
                    justify-content: center;
                    margin-right: 20px;
                    width: 42px;
                }

                .home-link-button {
                    text-decoration: none;
                }
            }

            .right-nav-section {
                display: flex;
                align-items: center;

                .login-link {
                    text-decoration: none;
                }
            }
        }
    }

    .site-main {
        padding: 100px 360px;
        display: flex;
        justify-content: center;

        .home-wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 720px;

            .hero-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-bottom: 80px;

                .green-text {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 133%;
                    color: token.$global-color-brand-base-default;
                }

                .hero-title {
                    font-weight: 300;
                    font-size: 48px;
                    line-height: 60px;
                    text-align: center;
                    letter-spacing: -1px;
                    color: token.$global-color-text-loud-default;
                    margin: 20px;
                }

                .hero-subtitle {
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    letter-spacing: 0.16px;
                    color: token.$global-color-text-normal-default;
                }
            }

            .home-body-wrapper {
                .home-body-paragraph {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 160%;
                    letter-spacing: 0.16px;
                    color: token.$global-color-text-normal-default;
                }

                .home-body-title {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 32px;
                    line-height: 44px;
                    letter-spacing: -0.64px;
                    color: token.$global-color-text-loud-default;
                    margin: 40px 0px 0px;
                }

                .home-body-subtitle {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 24px;
                    line-height: 32px;
                    letter-spacing: 0.16px;
                    color: token.$global-color-text-loud-default;
                    margin: 40px 0px 0px;
                }
            }
        }

        .login-wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 720px;

            .login-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .reaktor-logo {
                    align-items: center;
                    background-color: #151515;
                    border-radius: 5px;
                    display: flex;
                    height: 42px;
                    justify-content: center;
                    width: 42px;
                }

                .login-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 30px;
                    margin-bottom: 20px;

                    .login-title {
                        font-weight: 300;
                        font-size: 30px;
                        line-height: 38px;
                        text-align: center;
                        letter-spacing: -0.32px;
                        color: token.$global-color-text-loud-default;
                        margin-bottom: 12px;
                    }

                    .login-subtitle {
                        margin-top: 0px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 133%;
                        text-align: center;
                        color: token.$global-color-text-normal-default;
                    }
                }

                .login-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-self: flex-start;
                    width: 100%;

                    .reaktor-input {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 24px;
                        width: 100%;

                        label {
                            align-items: center;
                            color: token.$input-color-helper-text-default;
                            display: flex;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            letter-spacing: 0.16px;
                            line-height: 133%;
                            margin-bottom: 4px;
                        }

                        input {
                            background: token.$input-color-bg-default;
                            border-radius: 8px;
                            border: 2px solid token.$input-color-border-default;
                            color: token.$input-color-text-placeholder;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            height: 48px;
                            line-height: 133%;
                            padding: 14px 12px;
                            width: 360px;
                        }
                    }

                    .remember-forget-wrapper {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 24px;

                        .remember-wrapper {
                            display: flex;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 133%;
                            align-items: center;
                            letter-spacing: 0.16px;
                            color: token.$input-color-text-val;
                        }

                        .forgot {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 133%;
                            letter-spacing: 0.16px;
                            color: token.$global-color-brand-base-default;
                        }
                    }

                    .reaktor-button {
                        margin: 10px 0px 0px;
                    }
                }

                .login-footer {
                    display: flex;
                    color: token.$global-color-text-quiet-default;
                    margin-top: 30px;

                    .green-text {
                        color: token.$global-color-brand-base-default;
                    }
                }
            }
        }
    }
}

.reaktor-button {
    align-items: center;
    background-color: token.$button-color-bg-secondary-default;
    border-radius: token.$button-border-radius-all-lg;
    border: token.$button-border-width-all solid token.$button-color-border-secondary-default;
    color: token.$button-color-text-secondary-default;
    display: flex;
    font-size: 16px;
    height: token.$button-sizing-height-lg;
    justify-content: center;
    margin: 0px token.$button-spacing-gap-lg;
    min-width: 69px;
    padding: 0px token.$button-spacing-padding-right-lg 0px token.$button-spacing-padding-left-lg;
}

.green-button {
    background-color: token.$button-color-bg-primary-default;
    border-color: token.$button-color-border-primary-default;
    color: token.$button-color-text-primary-default;
}

.upside-down-caret::after {
    content: "\005E";
    display: inline-block;
    margin-left: 5px;
    transform: rotate(180deg) translateY(3px);
}
