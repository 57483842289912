
// Do not edit directly
// Generated on Wed, 01 Feb 2023 22:07:56 GMT

$border-radius-none: 16px * 0;
$border-radius-sm: 16px * .125;
$border-radius-md: 16px * 0.375;
$border-radius-base: 16px * .25;
$border-radius-lg: 16px * .5;
$border-radius-xl: 16px * .75;
$border-radius-2xl: 16px * 1;
$border-radius-3xl: 16px * 1.5;
$border-radius-full: 16px * 10000;
$base-font-size: 16px;
$spacing-0: 0;
$spacing-1: 16px *.25;
$spacing-2: 16px *.5;
$spacing-3: 16px*.75;
$spacing-4: 16px*1;
$spacing-5: 16px*1.25;
$spacing-6: 16px*1.5;
$spacing-7: 16px*1.75;
$spacing-8: 16px*2;
$spacing-9: 16px*2.25;
$spacing-10: 16px*2.5;
$spacing-12: 16px*3;
$spacing-14: 16px*3.5;
$spacing-16: 16px*4;
$spacing-20: 16px*5;
$spacing-24: 16px*6;
$spacing-28: 16px*7;
$spacing-32: 16px*8;
$spacing-36: 16px*9;
$spacing-40: 16px*10;
$spacing-44: 16px*11;
$spacing-48: 16px*12;
$spacing-52: 16px*13;
$spacing-56: 16px*14;
$spacing-60: 16px*15;
$spacing-64: 16px*18;
$spacing-72: 16px*18;
$spacing-80: 16px*20;
$spacing-96: 16px*24;
$spacing-px: 1px;
$spacing-0-5: 16px * .125;
$spacing-1-5: 16px * .375;
$spacing-2-5: 16px * .625;
$spacing-3-5: 16px * .875;
$spacing-5-5: 16px*1.375;
$spacing-6-5: 16px*1.625;
$font-family-body: Averta Std;
$font-family-heading: Averta Std;
$font-family-mono: IBM Plex Mono;
$font-weight-hairline: Extrathin;
$font-weight-thin: Thin;
$font-weight-light: Light;
$font-weight-normal: Regular;
$font-weight-medium: medium;
$font-weight-semibold: Semibold;
$font-weight-bold: Bold;
$font-weight-extrabold: Extrabold;
$font-weight-black: Black;
$sizing-3xs: 16px*14;
$sizing-2xs: 16px*16;
$sizing-xs: 16px*20;
$sizing-sm: 16px*24;
$sizing-md: 16px*28;
$sizing-lg: 16px*32;
$sizing-xl: 16px*36;
$sizing-2xl: 16px*42;
$sizing-3xl: 16px*48;
$sizing-4xl: 16px*56;
$sizing-5xl: 16px*64;
$sizing-6xl: 16px*72;
$sizing-7xl: 16px*80;
$sizing-8xl: 16px*90;
$sizing-container-sm: 640px;
$sizing-container-md: 768px;
$sizing-container-lg: 1024px;
$sizing-container-xl: 1280px;
$color-white: #ffffff;
$color-black: #000000;
$color-white-alpha-50: rgba(240, 242, 253, 0.04);
$color-white-alpha-100: rgba(240, 242, 253, 0.06);
$color-white-alpha-200: rgba(240, 242, 253, 0.08);
$color-white-alpha-300: rgba(240, 242, 253, 0.16);
$color-white-alpha-400: rgba(240, 242, 253, 0.24);
$color-white-alpha-500: rgba(240, 242, 253, 0.36);
$color-white-alpha-600: rgba(240, 242, 253, 0.48);
$color-white-alpha-700: rgba(240, 242, 253, 0.64);
$color-white-alpha-800: rgba(240, 242, 253, 0.8);
$color-white-alpha-900: #f0f2fd;
$color-black-alpha-50: rgba(23, 23, 27, 0.04);
$color-black-alpha-100: rgba(23, 23, 27, 0.06);
$color-black-alpha-200: rgba(23, 23, 27, 0.08);
$color-black-alpha-300: rgba(23, 23, 27, 0.16);
$color-black-alpha-400: rgba(23, 23, 27, 0.24);
$color-black-alpha-500: rgba(23, 23, 27, 0.36);
$color-black-alpha-600: rgba(23, 23, 27, 0.48);
$color-black-alpha-700: rgba(23, 23, 27, 0.64);
$color-black-alpha-800: rgba(23, 23, 27, 0.8);
$color-black-alpha-900: rgba(23, 23, 27, 0.92);
$color-gray-50: #f9fafb;
$color-gray-100: #f2f4f7;
$color-gray-200: #eaecf0;
$color-gray-300: #d0d5dd;
$color-gray-400: #98a2b3;
$color-gray-500: #667085;
$color-gray-600: #475467;
$color-gray-700: #344054;
$color-gray-800: #1d2939;
$color-gray-900: #101828;
$color-red-50: #fff1f3;
$color-red-100: #ffe4e8;
$color-red-200: #fecdd6;
$color-red-300: #fea3b4;
$color-red-400: #fd6f8e;
$color-red-500: #f63d68;
$color-red-600: #e31b54;
$color-red-700: #c01048;
$color-red-800: #a11043;
$color-red-900: #89123e;
$color-orange-50: #fff6ed;
$color-orange-100: #ffead5;
$color-orange-200: #fddcab;
$color-orange-300: #feb273;
$color-orange-400: #fd853a;
$color-orange-500: #fb6514;
$color-orange-600: #ec4a0a;
$color-orange-700: #b54708;
$color-orange-800: #9c2a10;
$color-orange-900: #7e2410;
$color-yellow-50: #fffaeb;
$color-yellow-100: #fef0c7;
$color-yellow-200: #fedf89;
$color-yellow-300: #fec84b;
$color-yellow-400: #fdb022;
$color-yellow-500: #f79009;
$color-yellow-600: #dc6803;
$color-yellow-700: #b54708;
$color-yellow-800: #93370d;
$color-yellow-900: #7a2e0e;
$color-green-50: #ecfdf3;
$color-green-100: #d1fadf;
$color-green-200: #a6f4c5;
$color-green-300: #6ce9a6;
$color-green-400: #32d583;
$color-green-500: #12b76a;
$color-green-600: #039855;
$color-green-700: #027a48;
$color-green-800: #05603a;
$color-green-900: #054f31;
$color-teal-50: #e0faf5;
$color-teal-100: #c3eae2;
$color-teal-200: #a2d9cf;
$color-teal-300: #81c9bc;
$color-teal-400: #5fb9a9;
$color-teal-500: #46a08f;
$color-teal-600: #337c6f;
$color-teal-700: #225a4f;
$color-teal-800: #0e3630;
$color-teal-900: #001510;
$color-blue-50: #eff8ff;
$color-blue-100: #d1e9ff;
$color-blue-200: #b2ddff;
$color-blue-300: #84caff;
$color-blue-400: #53b1fd;
$color-blue-500: #2e90fa;
$color-blue-600: #1570ef;
$color-blue-700: #175cd3;
$color-blue-800: #1849a9;
$color-blue-900: #194185;
$color-cyan-50: #ddfdff;
$color-cyan-100: #b6f5f8;
$color-cyan-200: #8decf2;
$color-cyan-300: #63e5eb;
$color-cyan-400: #3edce6;
$color-cyan-500: #2bc2cd;
$color-cyan-600: #1c989f;
$color-cyan-700: #0d6d72;
$color-cyan-800: #004146;
$color-cyan-900: #001819;
$color-pink-50: #fdf2fa;
$color-pink-100: #fce7f6;
$color-pink-200: #fcceee;
$color-pink-300: #faa7e0;
$color-pink-400: #f670c7;
$color-pink-500: #ee46bc;
$color-pink-600: #dd2590;
$color-pink-700: #c11574;
$color-pink-800: #9e165f;
$color-pink-900: #851651;
$color-purple-50: #f4f3ff;
$color-purple-100: #ebe9fe;
$color-purple-200: #d9d6fe;
$color-purple-300: #bdb4fe;
$color-purple-400: #9b8afb;
$color-purple-500: #7a5af8;
$color-purple-600: #6938ef;
$color-purple-700: #5925dc;
$color-purple-800: #4a1fb8;
$color-purple-900: #3e1c96;
$border-width-1: 1px;
$border-width-2: 2px;
$border-width-3: 3px;
$border-width-4: 4px;
$border-width-none: 0;
$box-shadow-0: [object Object];
$box-shadow-1: [object Object];
$global-color-transparent-default: rgba(0,0,0,0);
$global-color-transparent-hover: rgba(240, 242, 253, 0.04);
$global-color-transparent-active: rgba(240, 242, 253, 0.06);
$global-color-text-loud-default: #1d2939;
$global-color-text-loud-hover: #101828;
$global-color-text-loud-active: #101828;
$global-color-text-normal-default: #475467;
$global-color-text-normal-hover: #344054;
$global-color-text-normal-active: #1d2939;
$global-color-text-quiet-default: #667085;
$global-color-text-quiet-hover: #475467;
$global-color-text-quiet-active: #344054;
$global-color-surface-00-default: #ffffff;
$global-color-surface-00-hover: #ffffff;
$global-color-surface-00-active: #ffffff;
$global-color-surface-01-default: #f9fafb;
$global-color-surface-01-hover: #f9fafb;
$global-color-surface-01-active: #f9fafb;
$global-color-surface-02-default: #f2f4f7;
$global-color-surface-02-hover: #eaecf0;
$global-color-surface-02-active: #d0d5dd;
$global-color-surface-03-default: #d0d5dd;
$global-color-surface-04-default: #98a2b3;
$global-color-brand-base-default: #32d583;
$global-color-brand-base-hover: #12b76a;
$global-color-brand-base-active: #039855;
$global-color-brand-secondary-default: #d1fadf;
$global-color-brand-secondary-hover: #a6f4c5;
$global-color-brand-secondary-active: #6ce9a6;
$global-color-info-base-default: #1570ef;
$global-color-info-base-hover: #175cd3;
$global-color-info-base-active: #1849a9;
$global-color-info-secondary-hover: #eff8ff;
$global-color-info-secondary-default: #d1e9ff;
$global-color-info-secondary-active: #b2ddff;
$global-color-success-base-hover: #32d583;
$global-color-success-base-default: #12b76a;
$global-color-success-base-active: #039855;
$global-color-success-secondary-default: #ecfdf3;
$global-color-success-secondary-hover: #d1fadf;
$global-color-success-secondary-active: #a6f4c5;
$global-color-warning-base-default: #fdb022;
$global-color-warning-base-hover: #f79009;
$global-color-warning-base-active: #dc6803;
$global-color-warning-secondary-default: #fffaeb;
$global-color-warning-secondary-hover: #fef0c7;
$global-color-warning-secondary-active: #fedf89;
$global-color-error-base-default: #e31b54;
$global-color-error-base-hover: #c01048;
$global-color-error-base-active: #a11043;
$global-color-error-secondary-default: #fff1f3;
$global-color-error-secondary-hover: #ffe4e8;
$global-color-error-secondary-active: #fecdd6;
$global-color-tier-low-default: linear-gradient(123deg, #ffae80 0%, #bb9ce3 100%);
$global-color-tier-mid-default: linear-gradient(135deg, #5fb9a9 0%, #fffcb3 100%);
$global-color-tier-high-default: linear-gradient(45deg, #1f76fe 0%, #fa84d8 100%);
$global-color-tier-platinum-default: linear-gradient(0deg, #ffcdb1 0%, #cce6cb 62.25%);
$global-color-tier-common-default: linear-gradient(135deg, #f0f2fd 0%, #8a94a6 100%);
$global-color-states-focus: #1570ef;
$global-color-states-invalid: #e31b54;
$global-color-states-disabled-background: #eaecf0;
$global-color-states-disabled-foreground: #98a2b3;
$global-opacity-disabled: 50%;
$global-border-width-outline: 3px;
$global-border-width-invalid: 3px;
$global-letter-spacing-text-xs: 0.32px;
$global-letter-spacing-text-sm: 0.16px;
$global-letter-spacing-text-md: 0px;
$global-letter-spacing-text-lg: 0.16px;
$global-letter-spacing-heading-tame-xs: 0.16px;
$global-letter-spacing-heading-tame-sm: -0.32px;
$global-letter-spacing-heading-tame-md: -0.64px;
$global-letter-spacing-heading-tame-lg: -1px;
$global-letter-spacing-heading-tame-xl: -1px;
$global-letter-spacing-heading-tame-mobile-lg: -0.96px;
$global-letter-spacing-heading-tame-mobile-xl: -1px;
$global-letter-spacing-heading-expressive-xs: 0.16px;
$global-letter-spacing-heading-expressive-sm: -0.32px;
$global-letter-spacing-heading-expressive-md: -0.64px;
$global-letter-spacing-heading-expressive-lg: -1px;
$global-letter-spacing-heading-expressive-xl: -1px;
$global-letter-spacing-heading-expressive-mobile-lg: -0.96px;
$global-letter-spacing-heading-expressive-mobile-xl: -1px;
$global-font-size-text-xs: 16px*.75;
$global-font-size-text-sm: 16px*.875;
$global-font-size-text-md: 16px;
$global-font-size-text-lg: 16px*1.25;
$global-font-size-heading-xs: 16px*1.5;
$global-font-size-heading-sm: 16px*1.875;
$global-font-size-heading-md: 16px*2;
$global-font-size-heading-lg: 16px*3;
$global-font-size-heading-xl: 16px*4;
$global-font-size-heading-mobile-lg: 16px*1.75;
$global-font-size-heading-mobile-xl: 16px*2;
$global-line-height-text-xs: 133%;
$global-line-height-text-sm: 133%;
$global-line-height-text-md: 133%;
$global-line-height-text-lg: 160%;
$global-line-height-heading-tame-xs: 32px;
$global-line-height-heading-tame-sm: 38px;
$global-line-height-heading-tame-md: 44px;
$global-line-height-heading-tame-lg: 60px;
$global-line-height-heading-tame-xl: 72px;
$global-line-height-heading-tame-mobile-lg: 34px;
$global-line-height-heading-tame-mobile-xl: 38px;
$global-line-height-heading-expressive-xs: 20px;
$global-line-height-heading-expressive-sm: 26px;
$global-line-height-heading-expressive-md: 30px;
$global-line-height-heading-expressive-lg: 38px;
$global-line-height-heading-expressive-xl: 54px;
$global-line-height-heading-expressive-mobile-lg: 34px;
$global-line-height-heading-expressive-mobile-xl: 38px;
$global-typography-text-normal-xs: [object Object];
$global-typography-text-normal-sm: [object Object];
$global-typography-text-normal-md: [object Object];
$global-typography-text-normal-lg: [object Object];
$global-typography-text-quiet-xs: [object Object];
$global-typography-text-quiet-sm: [object Object];
$global-typography-text-quiet-md: [object Object];
$global-typography-text-quiet-lg: [object Object];
$global-typography-text-loud-xs: [object Object];
$global-typography-text-loud-sm: [object Object];
$global-typography-text-loud-md: [object Object];
$global-typography-text-loud-lg: [object Object];
$global-typography-heading-tame-xs: [object Object];
$global-typography-heading-tame-sm: [object Object];
$global-typography-heading-tame-md: [object Object];
$global-typography-heading-tame-lg: [object Object];
$global-typography-heading-tame-xl: [object Object];
$global-typography-heading-tame-mobile-lg: [object Object];
$global-typography-heading-tame-mobile-xl: [object Object];
$global-typography-heading-expressive-xs: [object Object];
$global-typography-heading-expressive-sm: [object Object];
$global-typography-heading-expressive-md: [object Object];
$global-typography-heading-expressive-lg: [object Object];
$global-typography-heading-expressive-xl: [object Object];
$global-typography-heading-expressive-mobile-lg: [object Object];
$global-typography-heading-expressive-mobile-xl: [object Object];
$accordion-color-text-button-default: #1d2939;
$accordion-color-text-button-hover: #1d2939;
$accordion-color-text-button-active: #1d2939;
$accordion-color-text-panel-default: #475467;
$accordion-color-bg-button-default: rgba(0,0,0,0);
$accordion-color-bg-button-hover: rgba(240, 242, 253, 0.04);
$accordion-color-bg-button-active: rgba(240, 242, 253, 0.06);
$accordion-color-bg-panel-default: rgba(0,0,0,0);
$accordion-color-border-item-default: #98a2b3;
$accordion-color-border-panel-default: rgba(0,0,0,0);
$accordion-border-width-top-item: 1px;
$accordion-border-width-top-panel: 0;
$accordion-border-width-left-item: 0;
$accordion-border-width-right-item: 0;
$accordion-border-width-bottom-item: 0;
$accordion-spacing-padding-left-button: 16px*.75;
$accordion-spacing-padding-right-button: 16px*2.5;
$accordion-spacing-padding-x-panel: 16px*.75;
$accordion-spacing-padding-y-button: 16px *.5;
$accordion-spacing-padding-top-panel: 16px *.5;
$accordion-spacing-padding-bottom-panel: 16px*.75;
$accordion-typography-button: [object Object];
$accordion-typography-panel: [object Object];
$accordion-sizing-all-icon: 16px*1.5*1.5714285714;
$accordion-border-radius-all-item: 16px * 0;
$alert-color-text-success-solid: #ecfdf3;
$alert-color-text-success-subtle: #12b76a;
$alert-color-text-warning-solid: #fffaeb;
$alert-color-text-warning-subtle: #fdb022;
$alert-color-text-error-solid: #fff1f3;
$alert-color-text-error-subtle: #e31b54;
$alert-color-text-info-solid: #d1e9ff;
$alert-color-text-info-subtle: #1570ef;
$alert-color-bg-success-solid: #12b76a;
$alert-color-bg-success-subtle: #ecfdf3;
$alert-color-bg-warning-solid: #fdb022;
$alert-color-bg-warning-subtle: #fffaeb;
$alert-color-bg-error-solid: #e31b54;
$alert-color-bg-error-subtle: #fff1f3;
$alert-color-bg-info-solid: #1570ef;
$alert-color-bg-info-subtle: #d1e9ff;
$alert-color-border-success-solid: rgba(0,0,0,0);
$alert-color-border-success-subtle: rgba(0,0,0,0);
$alert-color-border-warning-solid: rgba(0,0,0,0);
$alert-color-border-warning-subtle: rgba(0,0,0,0);
$alert-color-border-error-solid: rgba(0,0,0,0);
$alert-color-border-error-subtle: rgba(0,0,0,0);
$alert-color-border-info-solid: rgba(0,0,0,0);
$alert-color-border-info-subtle: rgba(0,0,0,0);
$alert-border-radius-all-normal: 16px * 0.375;
$alert-border-radius-all-compact: 16px * 0.375;
$alert-sizing-all-icon-normal: 16px*1.375;
$alert-sizing-all-icon-compact: 16px*.75*1.5;
$alert-border-width-all: 0;
$alert-spacing-padding-left-normal: 16px*.75;
$alert-spacing-padding-left-compact: 16px *.5;
$alert-spacing-padding-right-normal: 16px*1;
$alert-spacing-padding-right-compact: 16px*2.5;
$alert-spacing-padding-bottom-normal: 16px * .875;
$alert-spacing-padding-bottom-compact: 16px *.5;
$alert-spacing-padding-top-normal: 16px * .875;
$alert-spacing-padding-top-compact: 16px *.5;
$alert-spacing-column-gap-normal: 16px *.5;
$alert-spacing-column-gap-compact: 16px *.5;
$alert-spacing-row-gap-normal: 16px *.25;
$alert-box-shadow: [object Object];
$alert-typography-title-normal: [object Object];
$alert-typography-title-compact: [object Object];
$alert-typography-description-normal: [object Object];
$alert-typography-description-compact: [object Object];
$avatar-border-radius-all-3xs: 16px * 10000;
$avatar-border-radius-all-2xs: 16px * 10000;
$avatar-border-radius-all-xs: 16px * 10000;
$avatar-border-radius-all-sm: 16px * 10000;
$avatar-border-radius-all-md: 16px * 10000;
$avatar-border-radius-all-lg: 16px * 10000;
$avatar-border-radius-all-xl: 16px * 10000;
$avatar-border-radius-all-2xl: 16px * 10000;
$avatar-border-radius-top-left-3xs: 16px * 10000;
$avatar-border-radius-top-left-2xs: 16px * 10000;
$avatar-border-radius-top-left-xs: 16px * 10000;
$avatar-border-radius-top-left-sm: 16px * 10000;
$avatar-border-radius-top-left-md: 16px * 10000;
$avatar-border-radius-top-left-lg: 16px * 10000;
$avatar-border-radius-top-left-xl: 16px * 10000;
$avatar-border-radius-top-left-2xl: 16px * 10000;
$avatar-border-radius-top-right-3xs: 16px * 10000;
$avatar-border-radius-top-right-2xs: 16px * 10000;
$avatar-border-radius-top-right-xs: 16px * 10000;
$avatar-border-radius-top-right-sm: 16px * 10000;
$avatar-border-radius-top-right-md: 16px * 10000;
$avatar-border-radius-top-right-lg: 16px * 10000;
$avatar-border-radius-top-right-xl: 16px * 10000;
$avatar-border-radius-top-right-2xl: 16px * 10000;
$avatar-border-radius-bottom-left-3xs: 16px * 10000;
$avatar-border-radius-bottom-left-2xs: 16px * 10000;
$avatar-border-radius-bottom-left-xs: 16px * 10000;
$avatar-border-radius-bottom-left-sm: 16px * 10000;
$avatar-border-radius-bottom-left-md: 16px * 10000;
$avatar-border-radius-bottom-left-lg: 16px * 10000;
$avatar-border-radius-bottom-left-xl: 16px * 10000;
$avatar-border-radius-bottom-left-2xl: 16px * 10000;
$avatar-border-radius-bottom-right-3xs: 16px * 10000;
$avatar-border-radius-bottom-right-2xs: 16px * 10000;
$avatar-border-radius-bottom-right-xs: 16px * 10000;
$avatar-border-radius-bottom-right-sm: 16px * 10000;
$avatar-border-radius-bottom-right-md: 16px * 10000;
$avatar-border-radius-bottom-right-lg: 16px * 10000;
$avatar-border-radius-bottom-right-xl: 16px * 10000;
$avatar-border-radius-bottom-right-2xl: 16px * 10000;
$avatar-sizing-all-3xs: 16px;
$avatar-sizing-all-2xs: 16px*1.25;
$avatar-sizing-all-xs: 16px*1.5;
$avatar-sizing-all-sm: 16px*2;
$avatar-sizing-all-md: 16px*3;
$avatar-sizing-all-lg: 16px*4;
$avatar-sizing-all-xl: 16px*6;
$avatar-sizing-all-2xl: 16px*8;
$avatar-sizing-all-badge-3xs: 16px*0.25;
$avatar-sizing-all-badge-2xs: 16px*0.375;
$avatar-sizing-all-badge-xs: 16px*0.5;
$avatar-sizing-all-badge-sm: 16px*0.625;
$avatar-sizing-all-badge-md: 16px;
$avatar-sizing-all-badge-lg: 16px*1.375;
$avatar-sizing-all-badge-xl: 16px*2;
$avatar-sizing-all-badge-2xl: 16px*2.75;
$avatar-box-shadow-default: [object Object];
$avatar-box-shadow-badge: [object Object];
$avatar-border-width-default: 1px;
$avatar-border-width-badge-default: 2px;
$avatar-color-bg-icon: #ffffff;
$avatar-color-bg-badge: #12b76a;
$avatar-color-text-icon: #32d583;
$avatar-color-border-default: #f2f4f7;
$avatar-color-border-badge: #ffffff;
$avatar-group-spacing-gap-3xs: 16px*-0.5;
$avatar-group-spacing-gap-2xs: 16px*-0.5;
$avatar-group-spacing-gap-xs: 16px*-0.5;
$avatar-group-spacing-gap-sm: 16px*-0.75;
$avatar-group-spacing-gap-md: 16px*-1.375;
$avatar-group-spacing-gap-lg: 16px*-1.75;
$avatar-group-spacing-gap-xl: 16px*-3.5;
$avatar-group-spacing-gap-2xl: 16px*-4.5;
$avatar-group-color-text: #1d2939;
$avatar-grouping-typography-3xs: [object Object];
$avatar-grouping-typography-2xs: [object Object];
$avatar-grouping-typography-xs: [object Object];
$avatar-grouping-typography-sm: [object Object];
$avatar-grouping-typography-md: [object Object];
$avatar-grouping-typography-lg: [object Object];
$avatar-grouping-typography-xl: [object Object];
$avatar-grouping-typography-2xl: [object Object];
$badge-color-text-solid: #d1fadf;
$badge-color-text-outline: #32d583;
$badge-color-text-subtle: #32d583;
$badge-color-bg-solid: #32d583;
$badge-color-bg-outline: rgba(0,0,0,0);
$badge-color-bg-subtle: #d1fadf;
$badge-color-border-solid: rgba(0,0,0,0);
$badge-color-border-outline: #32d583;
$badge-color-border-subtle: rgba(0,0,0,0);
$badge-typography: [object Object];
$badge-border-width-all: 1px;
$badge-border-radius-all: 16px * .25;
$badge-spacing-padding-x: 16px *.25;
$badge-spacing-padding-y: 0;
$breadcrumb-color-bg-container: rgba(0,0,0,0);
$breadcrumb-color-item-text-default: #475467;
$breadcrumb-color-item-text-link-hover: #475467;
$breadcrumb-color-item-text-link-active: #475467;
$breadcrumb-color-item-bg-default: rgba(0,0,0,0);
$breadcrumb-color-item-bg-hover: rgba(0,0,0,0);
$breadcrumb-color-item-bg-active: rgba(0,0,0,0);
$breadcrumb-color-separator-text: #475467;
$breadcrumb-color-separator-bg: rgba(0,0,0,0);
$breadcrumb-spacing-padding-y-container: 0;
$breadcrumb-spacing-padding-y-item: 0;
$breadcrumb-spacing-padding-y-separator: 0;
$breadcrumb-spacing-padding-x-container: 0;
$breadcrumb-spacing-padding-x-item: 0;
$breadcrumb-spacing-padding-x-separator: 0;
$breadcrumb-spacing-gap-container: 16px *.5;
$breadcrumb-typography: [object Object];
$breadcrumb-sizing-all-icon: 16px*.875*1.4285714286;
$breadcrumb-text-decoration-link-default: none;
$breadcrumb-text-decoration-link-hover: underline;
$breadcrumb-text-decoration-link-active: underline;
$breadcrumb-border-radius-container: 16px * 0;
$breadcrumb-border-radius-item: 16px * 0;
$breadcrumb-border-radius-separator: 16px * 0;
$breadcrumb-border-width-container: 0;
$breadcrumb-border-width-item: 0;
$breadcrumb-border-width-separator: 0;
$button-color-text-primary-default: #f9fafb;
$button-color-text-primary-hover: #f9fafb;
$button-color-text-primary-active: #f9fafb;
$button-color-text-secondary-default: #475467;
$button-color-text-secondary-hover: #475467;
$button-color-text-secondary-active: #475467;
$button-color-text-ghost-default: #32d583;
$button-color-text-ghost-hover: #32d583;
$button-color-text-ghost-active: #32d583;
$button-color-text-link-default: #32d583;
$button-color-text-link-hover: #12b76a;
$button-color-text-link-active: #039855;
$button-color-text-overlay-default: #32d583;
$button-color-text-overlay-hover: #32d583;
$button-color-text-overlay-active: #32d583;
$button-color-bg-primary-default: #32d583;
$button-color-bg-primary-hover: #12b76a;
$button-color-bg-primary-active: #039855;
$button-color-bg-secondary-default: #ffffff;
$button-color-bg-secondary-hover: #f9fafb;
$button-color-bg-secondary-active: #f9fafb;
$button-color-bg-ghost-default: rgba(0,0,0,0);
$button-color-bg-ghost-hover: rgba(240, 242, 253, 0.04);
$button-color-bg-ghost-active: rgba(240, 242, 253, 0.06);
$button-color-bg-link-default: rgba(0,0,0,0);
$button-color-bg-link-hover: rgba(0,0,0,0);
$button-color-bg-link-active: rgba(0,0,0,0);
$button-color-bg-overlay-default: rgba(240, 242, 253, 0.24);
$button-color-bg-overlay-hover: rgba(240, 242, 253, 0.16);
$button-color-bg-overlay-active: rgba(240, 242, 253, 0.08);
$button-color-border-primary-default: rgba(0,0,0,0);
$button-color-border-primary-hover: rgba(0,0,0,0);
$button-color-border-primary-active: rgba(0,0,0,0);
$button-color-border-secondary-default: #f2f4f7;
$button-color-border-secondary-hover: #eaecf0;
$button-color-border-secondary-active: #d0d5dd;
$button-color-border-ghost-default: rgba(0,0,0,0);
$button-color-border-ghost-hover: rgba(0,0,0,0);
$button-color-border-ghost-active: rgba(0,0,0,0);
$button-color-border-link-default: #32d583;
$button-color-border-link-hover: #12b76a;
$button-color-border-link-active: #039855;
$button-color-border-overlay-default: rgba(0,0,0,0);
$button-color-border-overlay-hover: rgba(0,0,0,0);
$button-color-border-overlay-active: rgba(0,0,0,0);
$button-border-radius-all-xs: 16px * .5;
$button-border-radius-all-sm: 16px * .5;
$button-border-radius-all-md: 16px * .5;
$button-border-radius-all-lg: 16px * .5;
$button-sizing-all-icon-xs: 16px*.875*1.5;
$button-sizing-all-icon-sm: 16px*.875*1.4285714286;
$button-sizing-all-icon-md: 16px*1*1.375;
$button-sizing-all-icon-lg: 16px*1*1.3333333333333333;
$button-sizing-height-xs: 16px*2.25;
$button-sizing-height-sm: 16px*2.5;
$button-sizing-height-md: 16px*2.75;
$button-sizing-height-lg: 16px*1*3;
$button-sizing-height-link-xs: 16px*2.25;
$button-sizing-height-link-sm: 16px*2.5;
$button-sizing-height-link-md: 16px*2.75;
$button-sizing-height-link-lg: 16px*1*3;
$button-border-width-all: 2px;
$button-border-width-bottom-link: 2px;
$button-font-size-xs: 16px*.875;
$button-font-size-sm: 16px*.875;
$button-font-size-md: 16px*1;
$button-font-size-lg: 16px*1;
$button-letter-spacing-xs: .32px;
$button-letter-spacing-sm: .16px;
$button-letter-spacing-md: 0px;
$button-letter-spacing-lg: -0.16px;
$button-font-family: Averta Std;
$button-typography-label-xs: [object Object];
$button-typography-label-sm: [object Object];
$button-typography-label-md: [object Object];
$button-typography-label-lg: [object Object];
$button-spacing-padding-left-xs: 16px*.75;
$button-spacing-padding-left-sm: 16px*1.25;
$button-spacing-padding-left-md: 16px*1.375;
$button-spacing-padding-left-lg: 16px*1;
$button-spacing-padding-right-xs: 16px*.75;
$button-spacing-padding-right-sm: 16px*1.25;
$button-spacing-padding-right-md: 16px*1.375;
$button-spacing-padding-right-lg: 16px*1;
$button-spacing-gap-xs: 16px *.5;
$button-spacing-gap-sm: 16px *.5;
$button-spacing-gap-md: 16px *.5;
$button-spacing-gap-lg: 16px *.5;
$button-group-spacing-gap-xs: 16px *.5;
$button-group-spacing-gap-sm: 16px *.5;
$button-group-spacing-gap-md: 16px * .625;
$button-group-spacing-gap-lg: 16px*.75;
$button-group-spacing-gap-attached-primary: 0;
$button-group-spacing-gap-attached-secondary: 1px*-1;
$button-group-border-radius-button-top-right-attached-xs: 16px * 0;
$button-group-border-radius-button-top-right-attached-sm: 16px * 0;
$button-group-border-radius-button-top-right-attached-md: 16px * 0;
$button-group-border-radius-button-top-right-attached-lg: 16px * 0;
$button-group-border-radius-button-bottom-right-attached-xs: 16px * 0;
$button-group-border-radius-button-bottom-right-attached-sm: 16px * 0;
$button-group-border-radius-button-bottom-right-attached-md: 16px * 0;
$button-group-border-radius-button-bottom-right-attached-lg: 16px * 0;
$button-group-border-radius-button-top-left-attached-xs: 16px * 0;
$button-group-border-radius-button-top-left-attached-sm: 16px * 0;
$button-group-border-radius-button-top-left-attached-md: 16px * 0;
$button-group-border-radius-button-top-left-attached-lg: 16px * 0;
$button-group-border-radius-button-bottom-left-attached-xs: 16px * 0;
$button-group-border-radius-button-bottom-left-attached-sm: 16px * 0;
$button-group-border-radius-button-bottom-left-attached-md: 16px * 0;
$button-group-border-radius-button-bottom-left-attached-lg: 16px * 0;
$button-group-border-radius-group: 16px * .25;
$button-group-button-width-all: 1px;
$checkbox-spacing-gap-xs: 16px *.5;
$checkbox-spacing-gap-sm: 16px *.5;
$checkbox-spacing-gap-md: 16px * .625;
$checkbox-spacing-gap-lg: 16px*.75;
$checkbox-color-bg-nofill-default: #f9fafb;
$checkbox-color-bg-nofill-hover: #f9fafb;
$checkbox-color-bg-nofill-active: #f9fafb;
$checkbox-color-bg-fill-default: #32d583;
$checkbox-color-bg-fill-hover: #12b76a;
$checkbox-color-bg-fill-active: #039855;
$checkbox-color-helper-text-default: #475467;
$checkbox-color-helper-text-action: #12b76a;
$checkbox-color-helper-text-invalid: #e31b54;
$checkbox-color-border-default: #32d583;
$checkbox-color-border-hover: #12b76a;
$checkbox-color-border-active: #039855;
$checkbox-border-radius-all-xs: 16px * .125;
$checkbox-border-radius-all-sm: 16px * .25;
$checkbox-border-radius-all-md: 16px * 0.375;
$checkbox-border-radius-all-lg: 16px * 0.375;
$checkbox-sizing-all-input-xs: 16px*1;
$checkbox-sizing-all-input-sm: 16px*1.25;
$checkbox-sizing-all-input-md: 16px*1.5;
$checkbox-sizing-all-input-lg: 16px*1.75;
$checkbox-sizing-all-icon-xs: 16px*.75*1.5;
$checkbox-sizing-all-icon-sm: 16px*.875*1.4285714286;
$checkbox-sizing-all-icon-md: 16px*1.375;
$checkbox-sizing-all-icon-lg: 16px*1.25*1.3333333333333333;
$chip-sizing-height-xs: 16px*.75*2.5;
$chip-sizing-height-sm: 16px*.875*2.2857142857;
$chip-sizing-height-md: 16px*2.125;
$chip-sizing-height-lg: 16px*1.25*2.2222222222222222;
$chip-spacing-gap-xs: 16px * .375;
$chip-spacing-gap-sm: 16px * .375;
$chip-spacing-gap-md: 16px *.5;
$chip-spacing-gap-lg: 16px *.5;
$chip-spacing-padding-x-xs: 16px *.5;
$chip-spacing-padding-x-sm: 16px *.5;
$chip-spacing-padding-x-md: 16px * .625;
$chip-spacing-padding-x-lg: 16px*.75;
$chip-border-radius-all-xs: 16px * 0.375;
$chip-border-radius-all-sm: 16px * 0.375;
$chip-border-radius-all-md: 16px * 0.375;
$chip-border-radius-all-lg: 16px * 0.375;
$chip-group-spacing-gap-xs: 16px * .375;
$chip-group-spacing-gap-sm: 16px *.5;
$chip-group-spacing-gap-md: 16px *.5;
$chip-group-spacing-gap-lg: 16px * .625;
$circular-progress-sizing-all-xs: 16px*.75*8.3333333333333333;
$circular-progress-sizing-all-sm: 16px*.875*10;
$circular-progress-sizing-all-md: 16px*11.25;
$circular-progress-sizing-all-lg: 16px*1.25*12.222222222222222;
$circular-progress-border-width-track-xs: 2px;
$circular-progress-border-width-track-sm: 4px;
$circular-progress-border-width-track-md: 2px*3;
$circular-progress-border-width-track-lg: 4px*2;
$circular-progress-color-val: #475467;
$circular-progress-color-label: #667085;
$circular-progress-typography-val-xs: [object Object];
$circular-progress-typography-val-sm: [object Object];
$circular-progress-typography-val-md: [object Object];
$circular-progress-typography-val-lg: [object Object];
$circular-progress-typography-label-xs: [object Object];
$circular-progress-typography-label-sm: [object Object];
$circular-progress-typography-label-md: [object Object];
$circular-progress-typography-label-lg: [object Object];
$code-color-text: #475467;
$code-color-bg: #d0d5dd;
$code-color-border: rgba(0,0,0,0);
$code-border-radius-all: 16px * .125;
$code-border-width-all: 0;
$code-spacing-padding-left: 16px * .125;
$code-spacing-padding-right: 16px * .125;
$code-spacing-padding-top: 0;
$code-spacing-padding-bottom: 0;
$code-typography: [object Object];
$description-list-color-border-default: #98a2b3;
$description-list-color-text-detail: #475467;
$description-list-color-text-term: #667085;
$description-list-typography-term-xs: [object Object];
$description-list-typography-term-sm: [object Object];
$description-list-typography-term-md: [object Object];
$description-list-typography-term-lg: [object Object];
$description-list-typography-detail-xs: [object Object];
$description-list-typography-detail-sm: [object Object];
$description-list-typography-detail-md: [object Object];
$description-list-typography-detail-lg: [object Object];
$description-list-sizing-all-icon-xs: 16px*.75*1.5;
$description-list-sizing-all-icon-sm: 16px*.875*1.4285714286;
$description-list-sizing-all-icon-md: 16px*.875*1.4285714286;
$description-list-sizing-all-icon-lg: 16px*.875*1.4285714286;
$description-list-border-width-top-default: 1px;
$description-list-border-width-bottom-default: 0;
$description-list-spacing-padding-y-default-xs: 16px *.25;
$description-list-spacing-padding-y-default-sm: 16px *.25;
$description-list-spacing-padding-y-default-md: 16px *.25;
$description-list-spacing-padding-y-default-lg: 16px *.25;
$divider-color-border-solid: #98a2b3;
$divider-color-border-dashed: #98a2b3;
$divider-border-width-all-solid: 1px;
$divider-border-width-all-dashed: 1px;
$drawer-border-width-all-dialog: 1px;
$drawer-border-width-top-footer: 0;
$drawer-box-shadow-dialog: [object Object];
$drawer-typography-header-desktop: [object Object];
$drawer-typography-header-mobile: [object Object];
$drawer-spacing-padding-x-body-desktop: 16px*3;
$drawer-spacing-padding-x-body-mobile: 16px*1;
$drawer-spacing-padding-x-footer-desktop: 16px*3.5;
$drawer-spacing-padding-x-footer-mobile: 16px*1;
$drawer-spacing-padding-right-header-desktop: 16px*7;
$drawer-spacing-padding-right-header-mobile: 16px*5;
$drawer-spacing-padding-left-header-desktop: 16px*3.5;
$drawer-spacing-padding-left-header-mobile: 16px*1;
$drawer-spacing-padding-y-body-desktop: 16px*2;
$drawer-spacing-padding-y-body-mobile: 16px*1;
$drawer-spacing-padding-y-footer-desktop: 16px*1.5;
$drawer-spacing-padding-y-footer-mobile: 16px*.75;
$drawer-spacing-padding-y-header-desktop: 16px*1.5;
$drawer-spacing-padding-y-header-mobile: 16px*.75;
$drawer-color-bg-dialog: #ffffff;
$drawer-color-bg-footer: rgba(0,0,0,0);
$drawer-color-border-dialog: #98a2b3;
$drawer-color-border-footer: rgba(0,0,0,0);
$drawer-color-text-header: #1d2939;
$input-color-bg-default: #ffffff;
$input-color-bg-hover: #ffffff;
$input-color-bg-active: #ffffff;
$input-color-helper-text-default: #475467;
$input-color-helper-text-action: #12b76a;
$input-color-helper-text-invalid: #e31b54;
$input-color-border-default: #f2f4f7;
$input-color-border-hover: #eaecf0;
$input-color-border-active: #f2f4f7;
$input-color-text-placeholder: #667085;
$input-color-text-val: #475467;
$input-border-radius-all-xs: 16px * 0.375;
$input-border-radius-all-sm: 16px * .5;
$input-border-radius-all-md: 16px * .5;
$input-border-radius-all-lg: 16px * .75;
$input-sizing-height-xs: 16px*.75*2.6666666666666667;
$input-sizing-height-sm: 16px*.875*2.8571428571;
$input-sizing-height-md: 16px*3;
$input-sizing-height-lg: 16px*1.25*2.6666666666666667;
$input-sizing-all-indicator-xs: 16px*.75*1.5;
$input-sizing-all-indicator-sm: 16px*.875*1.4285714286;
$input-sizing-all-indicator-md: 16px*1.375;
$input-sizing-all-indicator-lg: 16px*1.25*1.3333333333333333;
$input-border-width-all: 2px;
$input-spacing-padding-x-xs: 16px *.5;
$input-spacing-padding-x-sm: 16px * .625;
$input-spacing-padding-x-md: 16px*.75;
$input-spacing-padding-x-lg: 16px*.75;
$input-spacing-margin-left-label-xs: 16px *.5;
$input-spacing-margin-left-label-sm: 16px * .625;
$input-spacing-margin-left-label-md: 0;
$input-spacing-margin-left-label-lg: 16px * .875;
$input-spacing-margin-bottom-label-xs: 16px *.5;
$input-spacing-margin-bottom-label-sm: 16px * .625;
$input-spacing-margin-bottom-label-md: 16px *.25;
$input-spacing-margin-bottom-label-lg: 16px*.75;
$input-spacing-margin-top-label-xs: 16px * .125;
$input-spacing-margin-top-label-sm: 16px * .125;
$input-spacing-margin-top-label-md: 0;
$input-spacing-margin-top-label-lg: 1px;
$input-typography-label-xs: [object Object];
$input-typography-label-sm: [object Object];
$input-typography-label-md: [object Object];
$input-typography-label-lg: [object Object];
$input-typography-helper-text-xs: [object Object];
$input-typography-helper-text-sm: [object Object];
$input-typography-helper-text-md: [object Object];
$input-typography-helper-text-lg: [object Object];
$input-typography-content-xs: [object Object];
$input-typography-content-sm: [object Object];
$input-typography-content-md: [object Object];
$input-typography-content-lg: [object Object];
$kbd-color-text: #475467;
$kbd-color-bg: #f9fafb;
$kbd-color-border: #98a2b3;
$kbd-border-radius-all: 16px * 0.375;
$kbd-border-width-left: 2px;
$kbd-border-width-right: 2px;
$kbd-border-width-top: 2px;
$kbd-border-width-bottom: 4px;
$kbd-spacing-padding-x: 16px * .375;
$kbd-spacing-padding-top: 16px * .125;
$kbd-spacing-padding-bottom: 16px *.25;
$kbd-typography: [object Object];
$link-color-text-default: #1d2939;
$link-color-text-hover: #101828;
$link-color-text-active: #101828;
$link-color-bg-default: rgba(0,0,0,0);
$link-color-bg-hover: rgba(0,0,0,0);
$link-color-bg-active: rgba(0,0,0,0);
$link-color-border-default: rgba(0,0,0,0);
$link-color-border-hover: rgba(0,0,0,0);
$link-color-border-active: rgba(0,0,0,0);
$link-text-decoration-default: underline;
$link-text-decoration-hover: underline;
$link-text-decoration-active: underline;
$link-spacing-padding-y: 0;
$link-spacing-padding-x: 0;
$link-border-radius-all: 16px * 0;
$link-border-width-top: 0;
$link-border-width-right: 0;
$link-border-width-left: 0;
$link-border-width-bottom: 0;
$list-color-item: #475467;
$list-color-marker: #1d2939;
$list-spacing-padding-left: 16px *.5;
$list-typography-item-xs: [object Object];
$list-typography-item-sm: [object Object];
$list-typography-item-md: [object Object];
$list-typography-item-lg: [object Object];
$list-typography-marker-xs: [object Object];
$list-typography-marker-sm: [object Object];
$list-typography-marker-md: [object Object];
$list-typography-marker-lg: [object Object];
$list-sizing-all-marker-xs: 16px*.75;
$list-sizing-all-marker-sm: 16px*.875;
$list-sizing-all-marker-md: 16px;
$list-sizing-all-marker-lg: 16px*1.25;
$menu-border-radius-all-list-xs: 16px * 0.375;
$menu-border-radius-all-list-sm: 16px * .5;
$menu-border-radius-all-list-md: 16px * .75;
$menu-border-radius-all-list-lg: 16px * .75;
$menu-border-radius-all-item-xs: 16px * .25;
$menu-border-radius-all-item-sm: 16px * 0.375;
$menu-border-radius-all-item-md: 16px * .5;
$menu-border-radius-all-item-lg: 16px * .75;
$menu-border-radius-all-group-title-xs: 16px * .25;
$menu-border-radius-all-group-title-sm: 16px * 0.375;
$menu-border-radius-all-group-title-md: 16px * .5;
$menu-border-radius-all-group-title-lg: 16px * .75;
$menu-border-width-all-list: 1px;
$menu-border-width-all-item: 0;
$menu-border-width-all-divider: 1px;
$menu-spacing-gap-item-xs: 16px *.5;
$menu-spacing-gap-item-sm: 16px *.5;
$menu-spacing-gap-item-md: 16px *.5;
$menu-spacing-gap-item-lg: 16px *.5;
$menu-spacing-gap-list-xs: 0;
$menu-spacing-gap-list-sm: 0;
$menu-spacing-gap-list-md: 0;
$menu-spacing-gap-list-lg: 0;
$menu-spacing-padding-top-list-xs: 16px *.5;
$menu-spacing-padding-top-list-sm: 16px *.5;
$menu-spacing-padding-top-list-md: 16px *.5;
$menu-spacing-padding-top-list-lg: 16px *.5;
$menu-spacing-padding-top-item-xs: 16px * .375;
$menu-spacing-padding-top-item-sm: 16px * .625;
$menu-spacing-padding-top-item-md: 16px * .625;
$menu-spacing-padding-top-item-lg: 16px*.75;
$menu-spacing-padding-top-divider-xs: 16px*1;
$menu-spacing-padding-top-divider-sm: 16px*1.25;
$menu-spacing-padding-top-divider-md: 16px*1.375;
$menu-spacing-padding-top-divider-lg: 16px*1.5;
$menu-spacing-padding-top-group-title-xs: 16px*1.25;
$menu-spacing-padding-top-group-title-sm: 16px*1.5;
$menu-spacing-padding-top-group-title-md: 16px*1.75;
$menu-spacing-padding-top-group-title-lg: 16px*2;
$menu-spacing-padding-bottom-list-xs: 16px*.75;
$menu-spacing-padding-bottom-list-sm: 16px*.75;
$menu-spacing-padding-bottom-list-md: 16px*1;
$menu-spacing-padding-bottom-list-lg: 16px*1;
$menu-spacing-padding-bottom-item-xs: 16px * .375;
$menu-spacing-padding-bottom-item-sm: 16px * .625;
$menu-spacing-padding-bottom-item-md: 16px * .625;
$menu-spacing-padding-bottom-item-lg: 16px*.75;
$menu-spacing-padding-bottom-divider-xs: 16px*1;
$menu-spacing-padding-bottom-divider-sm: 16px*1.25;
$menu-spacing-padding-bottom-divider-md: 16px*1.375;
$menu-spacing-padding-bottom-divider-lg: 16px*1.5;
$menu-spacing-padding-bottom-group-title-xs: 16px *.25;
$menu-spacing-padding-bottom-group-title-sm: 16px *.25;
$menu-spacing-padding-bottom-group-title-md: 16px *.25;
$menu-spacing-padding-bottom-group-title-lg: 16px *.25;
$menu-spacing-padding-x-list-xs: 16px *.25;
$menu-spacing-padding-x-list-sm: 16px * .375;
$menu-spacing-padding-x-list-md: 16px *.5;
$menu-spacing-padding-x-list-lg: 16px * .625;
$menu-spacing-padding-x-divider-xs: 16px *.5;
$menu-spacing-padding-x-divider-sm: 16px * .625;
$menu-spacing-padding-x-divider-md: 16px*.75;
$menu-spacing-padding-x-divider-lg: 16px*.75;
$menu-spacing-padding-x-group-title-xs: 16px *.5;
$menu-spacing-padding-x-group-title-sm: 16px * .625;
$menu-spacing-padding-x-group-title-md: 16px*.75;
$menu-spacing-padding-x-group-title-lg: 16px*.75;
$menu-spacing-padding-left-item-xs: 16px *.5;
$menu-spacing-padding-left-item-sm: 16px * .625;
$menu-spacing-padding-left-item-md: 16px*.75;
$menu-spacing-padding-left-item-lg: 16px*.75;
$menu-spacing-padding-right-item-xs: 16px *.5;
$menu-spacing-padding-right-item-sm: 16px * .625;
$menu-spacing-padding-right-item-md: 16px*.75;
$menu-spacing-padding-right-item-lg: 16px*.75;
$menu-box-shadow-list: [object Object];
$menu-sizing-all-indicator-xs: 16px*.75*1.5;
$menu-sizing-all-indicator-sm: 16px*.875*1.4285714286;
$menu-sizing-all-indicator-md: 16px*1.375;
$menu-sizing-all-indicator-lg: 16px*1.25*1.3333333333333333;
$menu-typography-item-xs: [object Object];
$menu-typography-item-sm: [object Object];
$menu-typography-item-md: [object Object];
$menu-typography-item-lg: [object Object];
$menu-typography-group-title-xs: [object Object];
$menu-typography-group-title-sm: [object Object];
$menu-typography-group-title-md: [object Object];
$menu-typography-group-title-lg: [object Object];
$menu-color-bg-list-default: #f9fafb;
$menu-color-bg-item-default: rgba(0,0,0,0);
$menu-color-bg-item-hover: rgba(240, 242, 253, 0.04);
$menu-color-bg-item-active: rgba(240, 242, 253, 0.06);
$menu-color-text-item-default: #475467;
$menu-color-text-item-hover: #475467;
$menu-color-text-item-active: #475467;
$menu-color-text-group-title-default: #667085;
$menu-color-text-command-default: #667085;
$menu-color-border-list-default: #98a2b3;
$modal-border-radius-all-dialog: 16px * 1.5;
$modal-border-width-all-dialog: 1px;
$modal-border-width-top-footer: 0;
$modal-box-shadow-dialog: [object Object];
$modal-typography-header-desktop: [object Object];
$modal-typography-header-mobile: [object Object];
$modal-spacing-padding-x-body-desktop: 16px*3;
$modal-spacing-padding-x-body-mobile: 16px*1;
$modal-spacing-padding-x-footer-desktop: 16px*3.5;
$modal-spacing-padding-x-footer-mobile: 16px*1;
$modal-spacing-padding-right-header-desktop: 16px*5;
$modal-spacing-padding-right-header-mobile: 16px*5;
$modal-spacing-padding-left-header-desktop: 16px*3;
$modal-spacing-padding-left-header-mobile: 16px*1;
$modal-spacing-padding-y-body-desktop: 16px*2;
$modal-spacing-padding-y-body-mobile: 16px*1;
$modal-spacing-padding-y-footer-desktop: 16px*1.5;
$modal-spacing-padding-y-footer-mobile: 16px*.75;
$modal-spacing-padding-y-header-desktop: 16px*1.5;
$modal-spacing-padding-y-header-mobile: 16px*.75;
$modal-color-bg-dialog: #ffffff;
$modal-color-bg-footer: rgba(0,0,0,0);
$modal-color-border-dialog: #98a2b3;
$modal-color-border-footer: rgba(0,0,0,0);
$modal-color-text-header: #1d2939;
$number-input-color-border-stepper: #98a2b3;
$number-input-color-bg-stepper-default: rgba(0,0,0,0);
$number-input-color-bg-stepper-hover: rgba(240, 242, 253, 0.04);
$number-input-color-bg-stepper-active: rgba(240, 242, 253, 0.06);
$number-input-border-width-stepper: 1px;
$number-input-sizing-icon-stepper-xs: 16px*.75*1.3333333333333333;
$number-input-sizing-icon-stepper-sm: 16px*.875*1.4285714286;
$number-input-sizing-icon-stepper-md: 16px*1.375;
$number-input-sizing-icon-stepper-lg: 16px*1.25*1.3333333333333333;
$number-input-sizing-width-stepper-xs: 16px*.75*2.666666666666667;
$number-input-sizing-width-stepper-sm: 16px*.875*2.8571428571;
$number-input-sizing-width-stepper-md: 16px*2.875;
$number-input-sizing-width-stepper-lg: 16px*1.25*2.6666666666666667;
$overlay-color-bg-layer-1: #32d583;
$overlay-color-bg-layer-2: rgba(23, 23, 27, 0.8);
$overlay-opacity-layer-1: 15%;
$pin-input-spacing-gap-xs: 16px *.25;
$pin-input-spacing-gap-sm: 16px * .375;
$pin-input-spacing-gap-md: 16px * .375;
$pin-input-spacing-gap-lg: 16px * .375;
$popover-border-radius-all-content: 16px * 1;
$popover-border-width-all-content: 1px;
$popover-border-width-top-footer: 0;
$popover-box-shadow-content: [object Object];
$popover-typography-header: [object Object];
$popover-typography-body: [object Object];
$popover-spacing-padding-x-body: 16px*1.25;
$popover-spacing-padding-x-footer: 16px*1.25;
$popover-spacing-padding-y-header: 16px*.75;
$popover-spacing-padding-y-body: 16px*.75;
$popover-spacing-padding-y-footer: 16px*.75;
$popover-spacing-padding-left-header: 16px*1.25;
$popover-spacing-padding-right-header: 16px*3;
$popover-color-bg-content: #ffffff;
$popover-color-border-content: #98a2b3;
$popover-color-border-footer: rgba(0,0,0,0);
$popover-color-text-header: #1d2939;
$popover-color-text-body: #475467;
$progress-sizing-height-track-xs: 16px*.75*0.16666666666666667;
$progress-sizing-height-track-sm: 16px*.875*0.2857142857;
$progress-sizing-height-track-md: 16px*0.375;
$progress-sizing-height-track-lg: 16px*1.25*0.4444444444444444;
$progress-border-radius-all-track-xs: 16px * 10000;
$progress-border-radius-all-track-sm: 16px * 10000;
$progress-border-radius-all-track-md: 16px * 10000;
$progress-border-radius-all-track-lg: 16px * 10000;
$progress-color-bg-track-filled: #32d583;
$progress-color-bg-track-default: #f2f4f7;
$progress-color-bg-track-border: rgba(0,0,0,0);
$progress-border-width-all: 0;
$progress-box-shadow: [object Object];
$radio-border-radius-all-xs: 16px * 10000;
$radio-border-radius-all-sm: 16px * 10000;
$radio-border-radius-all-md: 16px * 10000;
$radio-border-radius-all-lg: 16px * 10000;
$radio-sizing-all-xs: 16px*.75*1.6666666666666667;
$radio-sizing-all-sm: 16px*.875*1.5714285714;
$radio-sizing-all-md: 16px*1.5;
$radio-sizing-all-lg: 16px*1.25*1.4444444444444444;
$radio-sizing-all-indicator-xs: 16px*.75*0.6666666666666667;
$radio-sizing-all-indicator-sm: 16px*.875*0.5714285714;
$radio-sizing-all-indicator-md: 16px*0.5;
$radio-sizing-all-indicator-lg: 16px*1.25*0.4444444444444444;
$radio-box-shadow-indicator: [object Object];
$skeleton-color-start: rgba(240, 242, 253, 0.16);
$skeleton-color-end: rgba(240, 242, 253, 0.36);
$skeleton-border-radius-all: 16px * .25;
$slider-sizing-all-thumb-xs: 16px*.75*1.3333333333333333;
$slider-sizing-all-thumb-sm: 16px*.875*1.2857142857;
$slider-sizing-all-thumb-md: 16px*1.375;
$slider-sizing-all-thumb-lg: 16px*1.25*1.3333333333333333;
$slider-sizing-height-track-xs: 16px*.75*0.16666666666666667;
$slider-sizing-height-track-sm: 16px*.875*0.2857142857;
$slider-sizing-height-track-md: 16px*0.375;
$slider-sizing-height-track-lg: 16px*1.25*0.4444444444444444;
$slider-border-radius-all-thumb-xs: 16px * 10000;
$slider-border-radius-all-thumb-sm: 16px * 10000;
$slider-border-radius-all-thumb-md: 16px * 10000;
$slider-border-radius-all-thumb-lg: 16px * 10000;
$slider-border-radius-all-track-xs: 16px * 10000;
$slider-border-radius-all-track-sm: 16px * 10000;
$slider-border-radius-all-track-md: 16px * 10000;
$slider-border-radius-all-track-lg: 16px * 10000;
$slider-color-bg-thumb-default: #32d583;
$slider-color-bg-thumb-hover: #12b76a;
$slider-color-bg-thumb-active: #039855;
$slider-color-bg-track-filled: #32d583;
$slider-color-bg-track-default: #f2f4f7;
$slider-box-shadow-thumb: [object Object];
$spinner-sizing-all-xs: 16px;
$spinner-sizing-all-sm: 16px*1.25;
$spinner-sizing-all-md: 16px*1.5;
$spinner-sizing-all-lg: 16px*2;
$spinner-sizing-all-xl: 16px*3;
$spinner-color-default: #1d2939;
$spinner-color-empty-area: #f2f4f7;
$spinner-box-shadow: [object Object];
$stat-color-text-label: #667085;
$stat-color-text-number: #1d2939;
$stat-color-text-help-text: #667085;
$stat-color-text-arrow: #667085;
$stat-spacing-margin-right-arrow-xs: 16px * .125;
$stat-spacing-margin-right-arrow-sm: 16px * .125;
$stat-spacing-margin-right-arrow-md: 16px * .125;
$stat-spacing-margin-right-arrow-lg: 16px * .125;
$stat-sizing-all-arrow-xs: 16px*.75*1.5;
$stat-sizing-all-arrow-sm: 16px*.75*1.5;
$stat-sizing-all-arrow-md: 16px*.75*1.5;
$stat-sizing-all-arrow-lg: 16px*.75*1.5;
$stat-typography-label-xs: [object Object];
$stat-typography-label-sm: [object Object];
$stat-typography-label-md: [object Object];
$stat-typography-label-lg: [object Object];
$stat-typography-help-text-xs: [object Object];
$stat-typography-help-text-sm: [object Object];
$stat-typography-help-text-md: [object Object];
$stat-typography-help-text-lg: [object Object];
$stat-typography-number-xs: [object Object];
$stat-typography-number-sm: [object Object];
$stat-typography-number-md: [object Object];
$stat-typography-number-lg: [object Object];
$stat-group-spacing-row-gap-xs: 16px *.5;
$stat-group-spacing-row-gap-sm: 16px *.5;
$stat-group-spacing-row-gap-md: 16px *.5;
$stat-group-spacing-row-gap-lg: 16px *.5;
$stat-group-spacing-column-gap-xs: 16px *.5;
$stat-group-spacing-column-gap-sm: 16px *.5;
$stat-group-spacing-column-gap-md: 16px *.5;
$stat-group-spacing-column-gap-lg: 16px *.5;
$switch-border-radius-all-track-xs: 16px * 10000;
$switch-border-radius-all-track-sm: 16px * 10000;
$switch-border-radius-all-track-md: 16px * 10000;
$switch-border-radius-all-track-lg: 16px * 10000;
$switch-border-radius-all-thumb-xs: 16px * 10000;
$switch-border-radius-all-thumb-sm: 16px * 10000;
$switch-border-radius-all-thumb-md: 16px * 10000;
$switch-border-radius-all-thumb-lg: 16px * 10000;
$switch-sizing-all-thumb-xs: 16px*.75*1.3333333333333333;
$switch-sizing-all-thumb-sm: 16px*.875*1.1428571429;
$switch-sizing-all-thumb-md: 16px*1.125;
$switch-sizing-all-thumb-lg: 16px*1.25*1.1111111111111111;
$switch-sizing-height-track-xs: 16px*.75*2;
$switch-sizing-height-track-sm: 16px*.875*2;
$switch-sizing-height-track-md: 16px*1.875;
$switch-sizing-height-track-lg: 16px*1.25*1.777777777777778;
$switch-sizing-width-track-xs: 16px*.75*3.666666666666667;
$switch-sizing-width-track-sm: 16px*.875*3.5714285714;
$switch-sizing-width-track-md: 16px*3.375;
$switch-sizing-width-track-lg: 16px*1.25*3.2222222222222222;
$switch-box-shadow-thumb: [object Object];
$switch-spacing-margin-top-label-xs: 16px *.25;
$switch-spacing-margin-top-label-sm: 16px *.25;
$switch-spacing-margin-top-label-md: 16px *.25;
$switch-spacing-margin-top-label-lg: 16px *.25;
$table-typography-heading-xs: [object Object];
$table-typography-heading-sm: [object Object];
$table-typography-heading-md: [object Object];
$table-typography-heading-lg: [object Object];
$table-typography-cell-xs: [object Object];
$table-typography-cell-sm: [object Object];
$table-typography-cell-md: [object Object];
$table-typography-cell-lg: [object Object];
$table-typography-caption-xs: [object Object];
$table-typography-caption-sm: [object Object];
$table-typography-caption-md: [object Object];
$table-typography-caption-lg: [object Object];
$table-color-border-container: rgba(0,0,0,0);
$table-color-border-simple-heading: #98a2b3;
$table-color-border-simple-cell: #98a2b3;
$table-color-border-striped-heading: rgba(0,0,0,0);
$table-color-border-striped-cell: rgba(0,0,0,0);
$table-color-border-unstyled-heading: rgba(0,0,0,0);
$table-color-border-unstyled-cell: rgba(0,0,0,0);
$table-color-text-heading: #667085;
$table-color-text-cell: #475467;
$table-color-text-caption: #475467;
$table-color-bg-container: rgba(0,0,0,0);
$table-color-bg-simple-heading: rgba(0,0,0,0);
$table-color-bg-simple-cell: rgba(0,0,0,0);
$table-color-bg-striped-heading: rgba(0,0,0,0);
$table-color-bg-striped-odd-cell: #f9fafb;
$table-color-bg-striped-even-cell: rgba(0,0,0,0);
$table-color-bg-unstyled-heading: rgba(0,0,0,0);
$table-color-bg-unstyled-cell: rgba(0,0,0,0);
$table-border-width-all-container: 1px;
$table-border-width-bottom-cell: 1px;
$table-border-width-bottom-heading: 1px;
$table-border-radius-all-container-xs: 16px * .75;
$table-border-radius-all-container-sm: 16px * .75;
$table-border-radius-all-container-md: 16px * .75;
$table-border-radius-all-container-lg: 16px * .75;
$table-box-shadow-container: [object Object];
$table-spacing-padding-x-container-xs: 16px*.75;
$table-spacing-padding-x-container-sm: 16px*.75;
$table-spacing-padding-x-container-md: 16px*.75;
$table-spacing-padding-x-container-lg: 16px*.75;
$table-spacing-padding-x-heading-xs: 16px *.5;
$table-spacing-padding-x-heading-sm: 16px * .625;
$table-spacing-padding-x-heading-md: 16px*.75;
$table-spacing-padding-x-heading-lg: 16px*.75;
$table-spacing-padding-x-cell-xs: 16px *.5;
$table-spacing-padding-x-cell-sm: 16px * .625;
$table-spacing-padding-x-cell-md: 16px*.75;
$table-spacing-padding-x-cell-lg: 16px*.75;
$table-spacing-padding-top-container-xs: 16px *.5;
$table-spacing-padding-top-container-sm: 16px *.5;
$table-spacing-padding-top-container-md: 16px *.5;
$table-spacing-padding-top-container-lg: 16px *.5;
$table-spacing-padding-top-heading-xs: 16px * .625;
$table-spacing-padding-top-heading-sm: 16px * .625;
$table-spacing-padding-top-heading-md: 16px*.75;
$table-spacing-padding-top-heading-lg: 16px * .875;
$table-spacing-padding-top-cell-xs: 16px * .625;
$table-spacing-padding-top-cell-sm: 16px*.75;
$table-spacing-padding-top-cell-md: 16px*.75;
$table-spacing-padding-top-cell-lg: 16px * .875;
$table-spacing-padding-bottom-container-xs: 16px*.75;
$table-spacing-padding-bottom-container-sm: 16px*.75;
$table-spacing-padding-bottom-container-md: 16px*.75;
$table-spacing-padding-bottom-container-lg: 16px*.75;
$table-spacing-padding-bottom-heading-xs: 16px * .375;
$table-spacing-padding-bottom-heading-sm: 16px * .375;
$table-spacing-padding-bottom-heading-md: 16px *.5;
$table-spacing-padding-bottom-heading-lg: 16px * .625;
$table-spacing-padding-bottom-cell-xs: 16px * .625;
$table-spacing-padding-bottom-cell-sm: 16px*.75;
$table-spacing-padding-bottom-cell-md: 16px*.75;
$table-spacing-padding-bottom-cell-lg: 16px * .875;
$table-spacing-gap-heading-xs: 16px * .625;
$table-spacing-gap-heading-sm: 16px * .625;
$table-spacing-gap-heading-md: 16px * .625;
$table-spacing-gap-heading-lg: 16px * .625;
$table-spacing-gap-cell-xs: 16px * .625;
$table-spacing-gap-cell-sm: 16px * .625;
$table-spacing-gap-cell-md: 16px * .625;
$table-spacing-gap-cell-lg: 16px * .625;
$table-sizing-all-heading-icon-xs: 16px*.75*1.5;
$table-sizing-all-heading-icon-sm: 16px*.875*1.4285714286;
$table-sizing-all-heading-icon-md: 16px*.875*1.4285714286;
$table-sizing-all-heading-icon-lg: 16px*.875*1.4285714286;
$table-sizing-all-cell-icon-xs: 16px*.875*1.4285714286;
$table-sizing-all-cell-icon-sm: 16px*.875*1.4285714286;
$table-sizing-all-cell-icon-md: 16px*1.375;
$table-sizing-all-cell-icon-lg: 16px*1.25*1.3333333333333333;
$tabs-color-text-solid-tab-selected-default: #ffffff;
$tabs-color-text-solid-tab-selected-hover: #ffffff;
$tabs-color-text-solid-tab-selected-active: #ffffff;
$tabs-color-text-solid-tab-unselected-default: #667085;
$tabs-color-text-solid-tab-unselected-hover: #667085;
$tabs-color-text-solid-tab-unselected-active: #667085;
$tabs-color-text-subtle-tab-selected-default: #32d583;
$tabs-color-text-subtle-tab-selected-hover: #32d583;
$tabs-color-text-subtle-tab-selected-active: #32d583;
$tabs-color-text-subtle-tab-unselected-default: #667085;
$tabs-color-text-subtle-tab-unselected-hover: #667085;
$tabs-color-text-subtle-tab-unselected-active: #667085;
$tabs-color-text-line-tab-selected-default: #1d2939;
$tabs-color-text-line-tab-selected-hover: #101828;
$tabs-color-text-line-tab-selected-active: #101828;
$tabs-color-text-line-tab-unselected-default: #667085;
$tabs-color-text-line-tab-unselected-hover: #475467;
$tabs-color-text-line-tab-unselected-active: #344054;
$tabs-color-bg-solid-tab-selected-default: #32d583;
$tabs-color-bg-solid-tab-selected-hover: #12b76a;
$tabs-color-bg-solid-tab-selected-active: #039855;
$tabs-color-bg-solid-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-bg-solid-tab-unselected-hover: rgba(240, 242, 253, 0.04);
$tabs-color-bg-solid-tab-unselected-active: rgba(240, 242, 253, 0.06);
$tabs-color-bg-solid-tablist-default: rgba(0,0,0,0);
$tabs-color-bg-subtle-tab-selected-default: #f9fafb;
$tabs-color-bg-subtle-tab-selected-hover: #f9fafb;
$tabs-color-bg-subtle-tab-selected-active: #f9fafb;
$tabs-color-bg-subtle-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-bg-subtle-tab-unselected-hover: rgba(240, 242, 253, 0.04);
$tabs-color-bg-subtle-tab-unselected-active: rgba(240, 242, 253, 0.06);
$tabs-color-bg-subtle-tablist-default: rgba(0,0,0,0);
$tabs-color-bg-line-tab-selected-default: rgba(0,0,0,0);
$tabs-color-bg-line-tab-selected-hover: rgba(0,0,0,0);
$tabs-color-bg-line-tab-selected-active: rgba(0,0,0,0);
$tabs-color-bg-line-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-bg-line-tab-unselected-hover: rgba(0,0,0,0);
$tabs-color-bg-line-tab-unselected-active: rgba(0,0,0,0);
$tabs-color-bg-line-tablist-default: rgba(0,0,0,0);
$tabs-color-border-solid-tab-selected-default: rgba(0,0,0,0);
$tabs-color-border-solid-tab-selected-hover: rgba(0,0,0,0);
$tabs-color-border-solid-tab-selected-active: rgba(0,0,0,0);
$tabs-color-border-solid-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-border-solid-tab-unselected-hover: rgba(0,0,0,0);
$tabs-color-border-solid-tab-unselected-active: rgba(0,0,0,0);
$tabs-color-border-solid-tablist-default: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-selected-default: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-selected-hover: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-selected-active: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-unselected-hover: rgba(0,0,0,0);
$tabs-color-border-subtle-tab-unselected-active: rgba(0,0,0,0);
$tabs-color-border-subtle-tablist-default: rgba(0,0,0,0);
$tabs-color-border-line-tab-selected-default: #32d583;
$tabs-color-border-line-tab-selected-hover: #12b76a;
$tabs-color-border-line-tab-selected-active: #039855;
$tabs-color-border-line-tab-unselected-default: rgba(0,0,0,0);
$tabs-color-border-line-tab-unselected-hover: rgba(0,0,0,0);
$tabs-color-border-line-tab-unselected-active: rgba(0,0,0,0);
$tabs-color-border-line-tablist-default: #f2f4f7;
$tabs-border-radius-all-tab-xs: 16px * 10000;
$tabs-border-radius-all-tab-sm: 16px * 10000;
$tabs-border-radius-all-tab-md: 16px * 10000;
$tabs-border-radius-all-tab-lg: 16px * 10000;
$tabs-border-radius-all-tablist-xs: 16px * 0;
$tabs-border-radius-all-tablist-sm: 16px * 0;
$tabs-border-radius-all-tablist-md: 16px * 0;
$tabs-border-radius-all-tablist-lg: 16px * 0;
$tabs-sizing-height-tab-xs: 16px*2.25;
$tabs-sizing-height-tab-sm: 16px*2.5;
$tabs-sizing-height-tab-md: 16px*2.5;
$tabs-sizing-height-tab-lg: 16px*3;
$tabs-border-width-all-solid-tab: 0;
$tabs-border-width-all-solid-tablist: 0;
$tabs-border-width-all-soft-tab: 0;
$tabs-border-width-all-soft-tablist: 0;
$tabs-border-width-bottom-line-tab: 2px;
$tabs-border-width-bottom-line-tablist: 1px;
$tabs-typography-tab-xs: [object Object];
$tabs-typography-tab-sm: [object Object];
$tabs-typography-tab-md: [object Object];
$tabs-typography-tab-lg: [object Object];
$tabs-spacing-gap-tab-xs: 16px *.5;
$tabs-spacing-gap-tab-sm: 16px *.5;
$tabs-spacing-gap-tab-md: 16px *.5;
$tabs-spacing-gap-tab-lg: 16px *.5;
$tabs-spacing-gap-tablist-xs: 0;
$tabs-spacing-gap-tablist-sm: 0;
$tabs-spacing-gap-tablist-md: 0;
$tabs-spacing-gap-tablist-lg: 0;
$tabs-spacing-padding-x-tab-xs: 16px*.75;
$tabs-spacing-padding-x-tab-sm: 16px*1.25;
$tabs-spacing-padding-x-tab-md: 16px*1.375;
$tabs-spacing-padding-x-tab-lg: 16px*1.5;
$tabs-spacing-padding-x-panel-xs: 16px*.75;
$tabs-spacing-padding-x-panel-sm: 16px*1.25;
$tabs-spacing-padding-x-panel-md: 16px*1.375;
$tabs-spacing-padding-x-panel-lg: 16px*1.5;
$tabs-spacing-padding-x-tablist-xs: 0;
$tabs-spacing-padding-x-tablist-sm: 0;
$tabs-spacing-padding-x-tablist-md: 0;
$tabs-spacing-padding-x-tablist-lg: 0;
$tabs-spacing-padding-y-panel-xs: 16px*2.25;
$tabs-spacing-padding-y-panel-sm: 16px*2.5;
$tabs-spacing-padding-y-panel-md: 16px*2.5;
$tabs-spacing-padding-y-panel-lg: 16px*3;
$tabs-spacing-padding-y-tablist-xs: 0;
$tabs-spacing-padding-y-tablist-sm: 0;
$tabs-spacing-padding-y-tablist-md: 0;
$tabs-spacing-padding-y-tablist-lg: 0;
$tabs-box-shadow-tab: [object Object];
$tag-sizing-height-xs: 16px*.75*2.5;
$tag-sizing-height-sm: 16px*.875*2.2857142857;
$tag-sizing-height-md: 16px*2.125;
$tag-sizing-height-lg: 16px*1.25*2.2222222222222222;
$tag-sizing-all-icon-xs: 16px*.75*1.5;
$tag-sizing-all-icon-sm: 16px*.875*1.4285714286;
$tag-sizing-all-icon-md: 16px*1.375;
$tag-sizing-all-icon-lg: 16px*1.25*1.3333333333333333;
$tag-spacing-gap-xs: 16px * .375;
$tag-spacing-gap-sm: 16px * .375;
$tag-spacing-gap-md: 16px * .375;
$tag-spacing-gap-lg: 16px *.5;
$tag-spacing-padding-x-xs: 16px *.5;
$tag-spacing-padding-x-sm: 16px *.5;
$tag-spacing-padding-x-md: 16px * .625;
$tag-spacing-padding-x-lg: 16px*.75;
$tag-border-radius-all-xs: 16px * 0.375;
$tag-border-radius-all-sm: 16px * 0.375;
$tag-border-radius-all-md: 16px * 0.375;
$tag-border-radius-all-lg: 16px * .5;
$tag-border-radius-all-close-button-xs: 16px * 0.375;
$tag-border-radius-all-close-button-sm: 16px * 0.375;
$tag-border-radius-all-close-button-md: 16px * 0.375;
$tag-border-radius-all-close-button-lg: 16px * .5;
$tag-typography-xs: [object Object];
$tag-typography-sm: [object Object];
$tag-typography-md: [object Object];
$tag-typography-lg: [object Object];
$tag-border-width-all-container: 1px;
$tag-border-width-all-close-button: 1px;
$tag-color-bg-solid-container: #1d2939;
$tag-color-bg-solid-close-button-default: rgba(0,0,0,0);
$tag-color-bg-solid-close-button-hover: #101828;
$tag-color-bg-solid-close-button-active: #101828;
$tag-color-bg-subtle-container: #f2f4f7;
$tag-color-bg-subtle-close-button-default: rgba(0,0,0,0);
$tag-color-bg-subtle-close-button-hover: rgba(240, 242, 253, 0.04);
$tag-color-bg-subtle-close-button-active: rgba(240, 242, 253, 0.06);
$tag-color-bg-outline-container: rgba(0,0,0,0);
$tag-color-bg-outline-close-button-default: rgba(0,0,0,0);
$tag-color-bg-outline-close-button-hover: rgba(240, 242, 253, 0.04);
$tag-color-bg-outline-close-button-active: rgba(240, 242, 253, 0.06);
$tag-color-border-solid-container: rgba(0,0,0,0);
$tag-color-border-solid-close-button-default: rgba(0,0,0,0);
$tag-color-border-solid-close-button-hover: rgba(0,0,0,0);
$tag-color-border-solid-close-button-active: rgba(0,0,0,0);
$tag-color-border-subtle-container: rgba(0,0,0,0);
$tag-color-border-subtle-close-button-default: rgba(0,0,0,0);
$tag-color-border-subtle-close-button-hover: rgba(0,0,0,0);
$tag-color-border-subtle-close-button-active: rgba(0,0,0,0);
$tag-color-border-outline-container: #1d2939;
$tag-color-border-outline-close-button-default: rgba(0,0,0,0);
$tag-color-border-outline-close-button-hover: rgba(0,0,0,0);
$tag-color-border-outline-close-button-active: rgba(0,0,0,0);
$tag-color-text-solid-container: #ffffff;
$tag-color-text-solid-close-button-default: #ffffff;
$tag-color-text-solid-close-button-hover: #ffffff;
$tag-color-text-solid-close-button-active: #ffffff;
$tag-color-text-subtle-container: #1d2939;
$tag-color-text-subtle-close-button-default: #1d2939;
$tag-color-text-subtle-close-button-hover: #1d2939;
$tag-color-text-subtle-close-button-active: #1d2939;
$tag-color-text-outline-container: #1d2939;
$tag-color-text-outline-close-button-default: #1d2939;
$tag-color-text-outline-close-button-hover: #1d2939;
$tag-color-text-outline-close-button-active: #1d2939;
$textarea-spacing-padding-x-xs: 16px *.5;
$textarea-spacing-padding-x-sm: 16px * .625;
$textarea-spacing-padding-x-md: 16px*.75;
$textarea-spacing-padding-x-lg: 16px*.75;
$textarea-spacing-padding-y-xs: 16px *.5;
$textarea-spacing-padding-y-sm: 16px * .625;
$textarea-spacing-padding-y-md: 16px*.75;
$textarea-spacing-padding-y-lg: 16px*.75;
$tooltip-color-text: #475467;
$tooltip-color-bg: #d0d5dd;
$tooltip-color-border: rgba(0,0,0,0);
$tooltip-border-radius-all: 16px * .5;
$tooltip-border-width-all: 0;
$tooltip-spacing-padding-y: 16px*.75;
$tooltip-spacing-padding-x: 16px*.75;
$tooltip-typography: [object Object];
$tooltip-box-shadow: [object Object];
$button-utility-border-radius-all-xs: 16px * 10000;
$button-utility-border-radius-all-sm: 16px * 10000;
$button-utility-border-radius-all-md: 16px * 10000;
$button-utility-border-radius-all-lg: 16px * 10000;
$button-utility-sizing-all-xs: 16px*.875*2.666666666666667;
$button-utility-sizing-all-sm: 16px*.875*2.8571428571;
$button-utility-sizing-all-md: 16px*1*2.75;
$button-utility-sizing-all-lg: 16px*1*2.6666666666666667;